import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';
import noteContext from '../context/notes/noteContext';
import { FaTimes } from 'react-icons/fa'; // Cross icon for removing prescription

const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [sortBy, setSortBy] = useState('date'); // Default sorting by date
  const { checkTokenExpiry } = useContext(noteContext);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Token not found');
        return;
      }
      checkTokenExpiry();
      try {
        const response = await axiosClient.post('/users/prescriptions', null, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const data = await response.data?.data?.flat()?.map(item => ({
            image: item.image,
            id: item._id,
            uploadedDate: new Date(item.createdAt).toLocaleDateString(), // Assuming the field 'createdAt' exists
          })) || [];
          setPrescriptions(data);
        } else {
          console.error('Failed to fetch prescriptions:');
          toast.error('Failed to fetch prescriptions');
        }
      } catch (error) {
        console.error('Error fetching prescriptions:', error);
        toast.error('Error fetching prescriptions');
      }
    };

    fetchPrescriptions();
  }, [checkTokenExpiry]);

  // Sorting function (only by date)
  const handleSort = () => {
    const sortedData = [...prescriptions];
    sortedData.sort((a, b) => new Date(a.uploadedDate) - new Date(b.uploadedDate)); // Sort by date (ascending)
    setPrescriptions(sortedData);
  };

  // Remove prescription function
  const handleRemovePrescription = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token not found');
      return;
    }

    try {
      const response = await axiosClient.delete(`/users/prescriptions/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setPrescriptions(prev => prev.filter(prescription => prescription.id !== id)); // Remove the prescription from state
        toast.success('Prescription removed successfully');
      } else {
        toast.error('Failed to remove prescription');
      }
    } catch (error) {
      console.error('Error removing prescription:', error);
      toast.error('Error removing prescription');
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Prescriptions</h1>

        {/* Sort By dropdown */}
        <div className="mb-4">
          <label htmlFor="sortBy" className="mr-2">Sort By:</label>
          <select
            id="sortBy"
            value={sortBy}
            onChange={handleSort}
            className="border p-2 rounded"
          >
            <option value="date">Date Uploaded</option>
          </select>
        </div>

        {prescriptions.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {/* Render prescriptions */}
            {prescriptions.map((image, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 relative"
              >
                {/* Cross icon to remove the prescription */}
                <button
                  onClick={() => handleRemovePrescription(image.id)}
                  className="absolute top-1 right-1 text-black-500 hover:text-red-700"
                >
                  <FaTimes />
                </button>

                <div className="flex justify-center mb-4">
                  <img
                    src={image.image}
                    alt={`Prescription ${image.id}`}
                    className="w-full h-auto rounded-md object-cover"
                  />
                </div>
                <div className="text-center">
                  <p className="text-sm text-gray-500 mb-2">File ID: {image.id}</p>
                  <p className="text-sm text-gray-500 mb-2">Uploaded on: {image.uploadedDate}</p>
                  <a
                    href={image.image}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    View Prescription
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">You don't have any uploaded prescriptions yet.</p>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Prescriptions;
