import React from "react";
import logo from "../assets/logo.png";
import { CiMail } from "react-icons/ci";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaTwitterSquare, FaYoutubeSquare } from "react-icons/fa";
import footer from "../assets/foot.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between bg-white overflow-hidden">
      {/* Mission and Vision Section */}
      <div className="px-5 lg:px-10 w-full lg:w-2/5 py-10 text-center lg:text-left">
        <img
          src={logo}
          className="w-1/2 lg:w-1/2 mt-5 mb-5 mx-auto lg:mx-0"
          alt="Logo"
        />
        <div className="flex flex-col sm:flex-row lg:ml-5 mx-2">
           {/* Our Mission */}
  <div className="sm:w-1/2 lg:w-full mb-5 sm:mb-0 lg:mb-8"> {/* Added lg:mb-8 for laptop view */}
    <div className="font-bold text-gray-900">Our Mission</div>
    <div className="text-sm text-gray-700">
      Help Indians to compare offline medicine prices and buy at best price within their reach.
    </div>
  </div>
  {/* Our Vision */}
  <div className="sm:w-1/2 lg:w-full lg:ml-8"> {/* Added lg:ml-8 for laptop view */}
    <h1 className="font-bold text-gray-900">Our Vision</h1>
    <div className="text-sm text-gray-700">
      Help Indian community with medicine prices which is affordable, trusted, and within their reach.
    </div>
  </div>
</div>
        <div className="h-20"></div> {/* Adds 5rem (80px) of space */}
        {/* Copyright Section */}
<div className="mt-5 text-left ml-4 text-sm text-black-500 font-bold">
  Copyright © {new Date().getFullYear()} Saverx Technologies Private Limited.<br />
   All rights reserved.
</div>

      </div>

      {/* Links and Contact Section */}
      <div className="relative flex flex-col lg:flex-row lg:w-4/5 bg-gradient-to-r from-orange-400 to-yellow-400 lg:bg-none p-5 lg:p-0">
        <img
          src={footer}
          className="absolute w-full h-full z-10 hidden lg:block"
          alt="Footer Background"
        />
        <div className="w-full lg:w-2/12 hidden lg:block"></div>
        <div className="z-20 flex flex-col sm:flex-row justify-between lg:w-10/12 mx-5 lg:mx-0 py-5 lg:py-10 space-y-5 sm:space-y-0">
          {/* Important Links Section */}
          <div className="sm:w-1/3">
            <div className="font-extrabold text-lg text-center lg:text-left text-gray-900 border-b-2 border-orange-400 pb-2 mb-5">Important Links</div>
            <ul className="w-full text-center lg:text-left">
              {[
                { name: "About Us", to: "/about" },
                { name: "FAQ's", to: "/faq" },
                { name: "Testimonials", to: "/testimonials" },
                { name: "Terms & Conditions", to: "/terms" },
                { name: "Cyber Security", to: "/cyber-security" },
                { name: "IP Policy", to: "/ip-policy" },
                { name: "Partner Login", to: "https://smart-pills-admin.web.app/" },
                { name: "Privacy Policy", to: "/privacy" },
              ].map((link, idx) => (
                <li key={idx} className="mb-3">
                  <Link to={link.to} className="hover-link font-bold text-black hover:text-orange-600 transition">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Queries Section */}
          <div className="sm:w-1/3">
            <div className="font-extrabold text-lg text-center lg:text-left text-gray-900 border-b-2 border-orange-400 pb-2 mb-5">Queries</div>
            <ul className="w-full text-center lg:text-left">
              {[
                { name: "Cancer", to: "/query?label=Cancer" },
                { name: "Chronic Care", to: "/query?label=Chronic%20Care" },
                { name: "Generics", to: "/query?label=Generics" },
                { name: "Special Needs", to: "/query?label=Special%20Needs" },
                { name: "Alliances", to: "/alliance" },
                { name: "Smart Champ", to: "/smartchamp" },
              ].map((link, idx) => (
                <li key={idx} className="mb-3">
                  <Link to={link.to} className="hover-link font-bold text-black hover:text-orange-600 transition">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Reach Us Section */}
          <div className="sm:w-1/3">
            <div className="font-extrabold text-lg text-center lg:text-left text-gray-900 border-b-2 border-orange-400 pb-2 mb-5">Reach Us</div>
            <div className="flex justify-center lg:justify-start mb-5">
              <CiMail className="my-auto mr-3 text-gray-700" fontSize="1.5rem" />
              <Link to="mailto:info@smartpills.in" className="hover-link font-bold text-black hover:text-orange-600 transition">
                info@smartpills.in
              </Link>
            </div>
            <div className="mt-8 mb-5 font-semibold text-center lg:text-left text-gray-900">Follow SmartPills on</div>
            <div className="flex justify-center lg:justify-start space-x-3">
              {[
                { icon: FaFacebook, to: "https://www.facebook.com/smartpillsindia" },
                { icon: FaInstagramSquare, to: "https://www.instagram.com/smartpillsindia/?igshid=MzRlODBiNWFlZA%3D%3D" },
                { icon: FaLinkedin, to: "https://www.linkedin.com/company/smartpillsindia/" },
                { icon: FaTwitterSquare, to: "https://twitter.com/SmartPillsIndia" },
                { icon: FaYoutubeSquare, to: "https://www.youtube.com/@Smart-Pills" },
              ].map(({ icon: Icon, to }, idx) => (
                <Link key={idx} to={to} className="hover-link social-icon text-white hover:text-orange-600 transition">
                  <Icon fontSize="1.5rem" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
