import React from "react";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    navigate("/"); // Assuming "/" is the home page route
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
      {/* Modal Block */}
      <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg max-w-md w-full">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-orange-500 mb-4 text-center">
          Thank You for Being Awesome Today!
        </h1>

        {/* Message */}
        <p className="text-sm md:text-base text-gray-700 text-center mb-6">
          We have received your message and would like to thank you for writing to us. 
          <br />
          We truly value your time and will address your concern to the best of our ability. 
          <br />
          <br />
          Be in touch!
        </p>

        {/* Home Button */}
        <button
          onClick={handleHomeRedirect}
          className="bg-orange-500 text-white px-4 py-2 text-sm font-medium rounded-md shadow-md hover:bg-orange-600 transition-all w-full"
        >
          HOME
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
