import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import { FaEye, FaTimes } from 'react-icons/fa';
import smartImage from '../assets/smart.jpg'; // Import the image

const SmartBuy = () => {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // To store file preview URL
  const navigate = useNavigate();
  const { checkTokenExpiry } = useContext(noteContext);

  useEffect(() => {
    // Disable scrolling when modal is open
    if (showUploadPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when modal is closed
    }

    return () => {
      document.body.style.overflow = 'auto'; // Clean up the style when component unmounts
    };
  }, [showUploadPopup]);

  const handleViewPrescriptions = () => {
    navigate('/prescriptions');
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setFilePreview(URL.createObjectURL(uploadedFile)); // Generate file preview URL
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file to upload');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('No authentication token found. Please log in.');
      return;
    }
    checkTokenExpiry();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axiosClient.post('/users/upload_prescription', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('File uploaded successfully');
        setFile(null); // Clear the uploaded file
        setFilePreview(null); // Clear the file preview
        setShowUploadPopup(false);
      } else {
        toast.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Error uploading file');
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFilePreview(null); // Clear the file preview
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col min-h-screen">
        <div className="container mx-auto px-4 py-8 flex flex-col flex-grow">
          {/* Centered Smart Buy Text */}
          <h1 className="text-4xl font-extrabold mb-6 text-center ml-20">Smart Buy</h1>

          <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 space-y-4 sm:space-y-0">
            {/* Left side: Image */}
            <div className="md:w-1/2 w-full mb-5 md:mb-0 flex justify-center md:justify-start relative">
              <img
                src={smartImage}
                alt="Smart Buy"
                className="w-full h-full object-cover md:w-3/4 md:max-w-xl md:h-[100%] md:-ml-20 -mt-20 md:-mt-24"
              />
            </div>

            {/* Right side: Buttons and Content */}
            <div className="md:w-1/2 w-full flex justify-center md:justify-start space-y-4">
              <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 space-y-4 sm:space-y-0">
                <button
                  onClick={() => setShowUploadPopup(true)}
                  className="bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Upload Prescription
                </button>
                <button
                  onClick={handleViewPrescriptions}
                  className="bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  View All Prescriptions
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Sticky footer logic */}
        <Footer />
      </div>

      {/* Upload Popup Modal */}
      {showUploadPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-30">
          <div className="bg-white p-6 rounded shadow-md w-80">
            <h2 className="text-xl font-bold mb-4">Upload Prescription</h2>
            <input
              type="file"
              onChange={handleFileChange}
              className="mb-4"
            />
            {/* Display file preview and actions */}
            {filePreview && (
              <div className="flex items-center justify-between mb-4">
                <a
                  href={filePreview}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                >
                  <FaEye /> View
                </a>
                <button
                  onClick={handleRemoveFile}
                  className="text-red-500 hover:text-red-700"
                >
                  <FaTimes /> Remove
                </button>
              </div>
            )}
            <div className="flex items-center justify-between">
              <button
                onClick={handleUpload}
                className="bg-gradient-to-r from-orange-400 to-yellow-400 hover:from-orange-500 hover:to-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Upload
              </button>
              <button
                onClick={() => setShowUploadPopup(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default SmartBuy;
