import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import OtpPopup from '../components/OtpPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoCheckmarkCircle } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const QueryBox = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const label = params.get('label');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    medicine_type: '',
    quantity: 1,
    form_type: label || '',
  });

  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [isSending, setIsSending] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [username, setusername]=useState(null)
  const [formFields, setFormFields] = useState([
    {
      id: "name",
      name: "name",
      type: "text",
      label: "Name",
      placeholder: "Enter your name",
      required: true,
    },
    {
      id: "mobile",
      name: "mobile",
      type: "text",
      label: "Mobile",
      placeholder: "Enter your mobile number",
      required: true,
    },
    {
      id: "medicine_type",
      name: "medicine_type",
      type: "text",
      label: "Medicine Type",
      placeholder: "Enter the medicine type",
      required: true,
    },
    {
      id: "quantity",
      name: "quantity",
      type: "number",
      label: "Quantity",
      placeholder: "Enter the quantity",
      required: true,
    },
    {
      id: "form_type",
      name: "form_type",
      type: "text",
      label: "Form Type",
      placeholder: "Form Type",
      required: false,
      readOnly: true,
    },
  ]);

  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    const mobile = localStorage.getItem("mobile");
    const name =JSON.parse(localStorage.getItem('user'))
    setusername(name)
    if (mobile) {
      setOtpVerified(true);
      setFormFields(prevFields => prevFields.filter(field => field.id !== "mobile"));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      form_type: label || '',
    }));
    window.scrollTo(0, 0);
  }, [label]);

  const handlePhoneChange = (value, data) => {
    // Extract phone number and country code
    setFormData({
      ...formData,
      mobile: value.replace(data.dialCode, '').trim(),
      countryCode: data.dialCode,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      // Allow only alphabets and spaces
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'mobile') {
      // Allow only numbers and limit to 10 digits
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'medicine_type') {
      // Allow alphanumeric characters
      if (/^[a-zA-Z0-9\s]*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'quantity') {
      // Allow only numbers
      if (/^\d*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    // Validate mobile number
  if (formData.mobile.length !== 10) {
    toast.error("Mobile number must be exactly 10 digits!");
    return;
  }
    setIsSending(true);
    try {
      const data = new FormData();
      data.append('phone_number', formData.mobile);
      data.append('role', 'new');

      const response = await axiosClient.post('/auth/send_otp', data);

      if (response.status === 200) {
        setShowOtpPopup(true);
      } else {
        toast.error('Failed to send OTP');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP');
    }
    setIsSending(false);
  };

  const handleSubmitQuery = async () => {
    setIsLoading(true);
    try {
      const data = new FormData();
      const mobile = localStorage.getItem("mobile");
      if (mobile) {
        data.append("mobile", mobile);
      } else {
        data.append("mobile", formData.mobile);
      }
      data.append('name', formData.name);
      data.append('medicine_type', formData.medicine_type);
      data.append('quantity', formData.quantity);
      data.append('form_type', formData.form_type);

      const response = await axiosClient.post('/query/create_query', data);

      if (response.status === 200) {
        toast.success('Form submitted successfully');
        setShowThankYou(true);
      } else {
        const errorBody = await response.text();
        console.error('Failed to submit form:', errorBody);
        toast.error('Failed to submit form. Try with a different phone number');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to submit form. Try with a different phone number');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowThankYou(false);
  };

  const handleHomeRedirect = () => {
    handleCloseModal();
    navigate('/');
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4 text-black text-center">Query Box</h1>
        <div className="flex justify-center">
          <form
            onSubmit={handleSendOtp}
            className="bg-[linear-gradient(90deg,#FB8802_0%,#FFE649_99.99%)] p-8 rounded shadow-md flex flex-col gap-4 w-full md:w-1/2 lg:w-1/3"
          >
          {/* Name Field */}
<div className="mb-4 relative">
  <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
  <input
    type="text"
    name="name"
    value={formData.name}
    onChange={handleChange}
    placeholder="Enter your name"
    className="w-full border-gray-500 border-2 px-3 py-1.5 rounded-md"
  />
</div>

{/* Mobile Field */}
<div className="mb-4 relative">
  <label className="block text-gray-700 text-sm font-bold mb-2">Mobile</label>
  <PhoneInput
    country={'in'}
    value={`${formData.countryCode || ''}${formData.mobile}`}
    onChange={handlePhoneChange}
    inputProps={{
      name: 'mobile',
      required: true,
      placeholder: 'Enter your mobile number',
      className: 'w-full border-gray-500 border-2 px-3 py-1.5 rounded-md pl-14', // Added padding-left for visibility of the country code
    }}
    containerClass="w-full"  // Ensures the container width is full
    buttonClass="w-12" // Controls the width of the country code button
  />
  {formData.mobile.length === 10 && (
    <IoCheckmarkCircle
      color="green"
      className="absolute right-3 top-2/3 transform -translate-y-1/2"
      size={24}
    />
  )}
</div>




{/* Medicine Type Field */}
<div className="mb-4 relative">
  <label className="block text-gray-700 text-sm font-bold mb-2">Medicine Name</label>
  <input
    type="text"
    name="medicine_type"
    value={formData.medicine_type}
    onChange={handleChange}
    placeholder="Enter the medicine Name"
    className="w-full border-gray-500 border-2 px-3 py-1.5 rounded-md"
  />
</div>

{/* Quantity Field */}
<div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2">Quantity</label>
  <input
    type="number"
    name="quantity"
    value={formData.quantity}
    onChange={handleChange}
    placeholder="Enter the quantity"
    className="w-full border-gray-500 border-2 px-3 py-1.5 rounded-md"
  />
</div>

{/* Form Type Field */}
{formData.form_type && (
  <div className="mb-4 relative">
    <label className="block text-gray-700 text-sm font-bold mb-2">Form Type</label>
    <input
      type="text"
      name="form_type"
      value={formData.form_type}
      onChange={handleChange}
      placeholder="Form Type"
      className="w-full border-gray-500 border-2 px-3 py-1.5 rounded-md"
      readOnly
    />
  </div>
)}
          
            <div className="flex items-center justify-end">
              {!otpVerified ? (
                <button
                  type="submit"
                  className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {isSending ? "Sending OTP..." : "Send OTP"}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmitQuery}
                  className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {showOtpPopup && (
        <OtpPopup
          mobile={formData.mobile}
          onClose={(isVerified) => {
            setShowOtpPopup(false);
            setOtpVerified(isVerified);
          }}
        />
      )}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative flex items-center justify-center">
            <div
              className="loader"
              style={{
                width: '80px',
                height: '80px',
                border: '8px solid rgba(255, 255, 255, 0.8)',
                borderTop: '8px solid orange',
                borderRadius: '50%',
                animation: 'spin 1.5s linear infinite',
              }}
            />
            <img
              src="/loader.png"
              alt="Loading..."
              style={{
                width: '60px',
                height: '60px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        </div>
      )}
      {showThankYou && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-r from-orange-400 to-yellow-400 p-6 md:p-8 rounded-lg shadow-lg max-w-md w-full relative">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-black text-2xl font-bold cursor-pointer hover:text-white hover:scale-110 transition-transform duration-200"
            >
              ×
            </button>
            <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">
              Thank You for Being Awesome Today!
            </h1>
            <p className="text-sm md:text-base text-gray-700 text-center mb-6">
              We have received your message and would like to thank you for writing to us.
              <br />
              we truly value your time and will address your concer to the best of our ability.
              <br />
              <br />
              Be in touch!
            </p>
            <button
              onClick={handleHomeRedirect}
              className="bg-orange-500 text-white px-4 py-2 text-sm font-medium rounded-md shadow-md hover:bg-orange-600 transition-all w-full"
            >
              HOME
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
      <Footer />
    </>
  );
};

export default QueryBox;
