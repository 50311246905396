import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../components/AxiosClient';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axiosClient.post('/testimonial/get_all_testimonial', null);
        console.log('Testimonial API response:', response.data.data);
        setTestimonials(response.data.data[0]); // Ensure we access the correct nested array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setError('Failed to fetch testimonials');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const truncateResponse = (response) => {
    const words = response.split(' ');
    if (words.length > 30) {
      return words.slice(0, 30).join(' ') + '...';
    }
    return response;
  };

  const handleSeeMore = (id) => {
    navigate(`/testimonial/${id}`);
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto py-8">
        <h1 className="text-center text-3xl font-semibold mb-8">Testimonials</h1>
        {loading ? (
          <p className="text-center">Loading testimonials...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : testimonials && testimonials.length > 0 ? (
          <div className="overflow-x-auto mx-8"> {/* Shift table to the right */}
            <table className="min-w-full table-auto border border-gray-300"> {/* Table border */}
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="py-2 px-4 text-left border-r">User</th> {/* Cell border */}
                  <th className="py-2 px-4 text-left border-r">Response</th> {/* Cell border */}
                  <th className="py-2 px-4 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {testimonials.map((testimonial) => (
                  <tr key={testimonial._id} className="border-b">
                    <td className="py-4 px-4 border-r">{testimonial.user}</td> {/* Cell border */}
                    <td className="py-4 px-4 border-r">{truncateResponse(testimonial.response)}</td> {/* Cell border */}
                    <td className="py-4 px-4">
                      <button
                        className="text-white px-4 py-2 rounded mt-2 bg-gradient-to-r from-yellow-400 to-orange-500 hover:bg-gradient-to-r hover:from-orange-500 hover:to-yellow-400 transition duration-300"
                        onClick={() => handleSeeMore(testimonial._id)}
                      >
                        See More
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center">No testimonials found.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Testimonials;
