import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../components/AxiosClient"; // Adjust the import based on your file structure
import { useNavigate } from "react-router-dom"; // For navigation
import noteContext from "../context/notes/noteContext";

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { checkTokenExpiry } = useContext(noteContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("Token not found");
                }
                checkTokenExpiry();
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                const response = await axiosClient.post("/users/userdata", null, config);

                if (response.status === 200) {
                    setUser(response.data.data[0]);
                } else {
                    throw new Error("Failed to fetch user data");
                }
            } catch (err) {
                console.error("Error fetching user data:", err);
                if (err.response?.status === 403) {
                    setError(
                        "Oops! You are a guest user. For the profile section, you will need to sign up first and create your profile."
                    );
                } else {
                    setError(err.message || "An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [checkTokenExpiry]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="flex-grow flex items-center justify-center">
                    <div className="container mx-auto px-4 py-8">
                        <div className="bg-gradient-to-r from-orange-500 to-yellow-400 text-white rounded-lg shadow-lg p-6 max-w-lg mx-auto text-center">
                            <h1 className="text-2xl font-semibold mb-4">Oops!</h1>
                            <p className="text-lg mb-6">{error}</p>
                            <button
                                onClick={() => navigate("/signup")} // Redirect to sign-up page
                                className="bg-white text-orange-500 font-bold py-2 px-4 rounded shadow-lg hover:bg-orange-100"
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
                <div className="text-center font-semibold text-2xl mt-5">My Profile</div>
                {user && (
                    <div className="flex flex-col items-center mt-5">
                        <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                            <div className="flex justify-between">
                                <div className="font-bold">Name</div>
                                <div>{user.name}</div>
                            </div>
                        </div>
                        <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                            <div className="flex justify-between">
                                <div className="font-bold">Email</div>
                                <div>{user.email}</div>
                            </div>
                        </div>
                        <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                            <div className="flex justify-between">
                                <div className="font-bold">Phone Number</div>
                                <div>{user.mobile}</div>
                            </div>
                        </div>
                        {user.subscription === true && (
                            <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                                <div className="flex justify-between">
                                    <div className="font-bold">Subscription</div>
                                    <div>You have bought a subscription plan</div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
