import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import noteContext from '../context/notes/noteContext';
import logo from '../assets/logo.png';
import pharmacyLogo from '../assets/pharmacy-logo.png';
import backgroundImage from '../assets/coupon.jpeg';
import '../Coupons.css';
import CustomDropdown from './customDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faPrint, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from "react-toastify";
import axiosClient from "../components/AxiosClient";
import html2canvas from "html2canvas";

const GetCoupons = () => {
    const location = useLocation();
    const { checkTokenExpiry } = useContext(noteContext);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const productDetails = location.state?.cartItems?.[0] || location.state || {};

    useEffect(() => {
        setSelectedProduct(null);
        setIsLoggedIn(true);
        initializeSelectedProduct();
    }, [location.state]);

    const initializeSelectedProduct = () => {
        console.log(productDetails, "=--==--=")
        if (productDetails.name) {
            setSelectedProduct({
                product_id: productDetails.product_id,
                vendor_id: productDetails.vendor_id,
                name: productDetails.name,
                mrp: (productDetails.mrp || 0) * (productDetails.quantity || 0),
                quantity: productDetails.quantity,
                discount: productDetails.discount,
                discounted_price: productDetails.discounted_price,
                packaging: productDetails.packaging,
                availableQuantity: productDetails.available_quantity,
                description: productDetails.description,
                contact_number: productDetails.contact_number,
                instructions: productDetails.instructions,
                couponid:productDetails.couponid
            });
        }
    };

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    // Button Handlers
    const handleText = () => {
        alert(`Message sent for ${selectedProduct?.name || 'product'}!`);
    };

    const handleEmail = () => {
        const emailBody = `Here is your coupon for ${selectedProduct?.name || 'product'}!`;
        const mailtoLink = `mailto:?subject=Your Coupon for ${selectedProduct?.name || 'Product'}&body=${encodeURIComponent(emailBody)}`;
        window.open(mailtoLink, '_blank');
    };

    const handleDownload = () => {
        if (!selectedProduct) {
            alert('No product selected to generate the coupon.');
            return;
        }

       
        // Find the coupon element
    const couponElement = document.querySelector(".shadow-md.rounded-lg.p-4");

    if (!couponElement) {
        alert('Coupon element not found!');
        return;
    }

    // Use html2canvas to capture the coupon element
    html2canvas(couponElement, { scale: 2 }).then((canvas) => {
        const link = document.createElement("a");
        link.download = `${selectedProduct.name || 'Coupon'}.png`;
        link.href = canvas.toDataURL("image/png");
        console.log(link, "=-=-=-=-", link.href)
        link.click();
        const dataUrl = canvas.toDataURL("image/png");
        const byteString = atob(dataUrl.split(',')[1]);  // Decode base64
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];  // Extract mime type
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
    }

    const file = new Blob([uintArray], { type: mimeString });
    updatecoupon(selectedProduct.couponid, file)
    }).catch((error) => {
        console.error("Error capturing coupon image:", error);
        toast.error("Failed to download the coupon image.");
    });
        
    
       {/* //const pdf = new jsPDF();
    
        // Add a title
        pdf.setFontSize(18);
        pdf.setFont('helvetica', 'bold');
        pdf.text('SmartPills Discount Coupon', 10, 20);
    
        // Add coupon details
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`Coupon Date: ${formattedDate}`, 10, 40);
        pdf.text(`Product: ${selectedProduct.name || 'N/A'}`, 10, 50);
        pdf.text(`Quantity: ${selectedProduct.quantity || 'N/A'}`, 10, 60);
        pdf.text(`Vendor ID: ${selectedProduct.vendor_id || 'N/A'}`, 10, 70);
        pdf.text(`Discount: INR ${selectedProduct.discount || 'N/A'}`, 10, 80);
        pdf.text(`MRP: INR ${selectedProduct.mrp || 'N/A'}`, 10, 90);
        pdf.text(`Save: ${selectedProduct.discounted_price || '0'}%`, 10, 100);
        pdf.text(`Valid Until: 48 Hours`, 10, 110);
    
        // Add additional notes
        pdf.setTextColor(255, 0, 0); // Red text
        pdf.text('This coupon is valid for 48 hours only.', 10, 130);
    
        // Save the PDF
        pdf.save(`${selectedProduct.name || 'Coupon'}.pdf`);*/}
    };

    const handlePrint = () => {
        // Find the coupon element
        const couponElement = document.querySelector(".shadow-md.rounded-lg.p-4");
    
        if (!couponElement) {
            alert("Coupon element not found!");
            return;
        }
    
        // Use html2canvas to capture the coupon element
        html2canvas(couponElement, { scale: 2 }).then((canvas) => {
            // Open a new window
            const printWindow = window.open("", "_blank");
            
            // Add the canvas as an image in the new window
            printWindow.document.write(`
                <!DOCTYPE html>
                <html>
                <head>
                    <title>Print Coupon</title>
                    <style>
                        body {
                            margin: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            background-color: #FFFFE0; /* Match coupon background color */
                        }
                    </style>
                </head>
                <body>
                    <img id="couponImage" src="${canvas.toDataURL("image/png")}" alt="Coupon" style="max-width: 100%; max-height: 100%;">
                </body>
                </html>
            `);
    
            // Ensure the image is fully loaded before triggering print
            printWindow.document.close();
            printWindow.focus();
    
            const image = printWindow.document.getElementById("couponImage");
            
            // Add an onload event listener to the image to ensure it is loaded before printing
            image.onload = () => {
                printWindow.print();
            };
        }).catch((error) => {
            console.error("Error rendering coupon for print:", error);
            toast.error("Failed to prepare the coupon for printing.");
        });
    };
    const getAuthConfig = () => {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");
        checkTokenExpiry();
        return {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };
      };
    

    const updatecoupon=async(couponid, file)=>{
        try {
            const config = getAuthConfig();
            const formData = new FormData();
            formData.append('coupon_id',couponid );
            formData.append("file", file)
            const response = await axiosClient.post(
              "https://www.api.smartpills.in/coupons/update_coupons_users",
              formData,
              config
            );
      
            console.log("API Response:", response.data);
      
            if (response.status === 200) {
              console.log("Cart Items:", response.data);
            
              // setCartItems(response.data.cart);
            } else {
              throw new Error("Failed to fetch cart items");
            }
          } catch (error) {
            console.error("Error fetching cart items:", error);
            toast.error("Error loading cart items.");
          }

    }

    return (
        <>
            <Navbar />
            <div
                className="flex flex-col justify-center items-center overflow-y-auto"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                }}
            >
                {isLoggedIn ? (
                    <div
                        className="container mx-auto flex flex-col items-center justify-center lg:flex-row lg:justify-between py-8 lg:flex lg:justify-between"
                        style={{ minHeight: 'calc(100vh - 80px)' }}
                    >
                        <div className="w-full lg:w-1/3 p-4 text-center lg:text-left lg:mt-[-350px]">
                            <h2 className="text-2xl sm:text-3xl font-bold text-white mt-[-30px] lg:mt-[-20px]">
                                Here is your coupon for: <span className="text-white block mt-2 lg:inline">{selectedProduct?.name || 'Product'}</span>
                            </h2>
                            <br />
                            <label className="block text-base sm:text-lg font-semibold mb-2 sm:mb-4 text-white sm:mt-[-0px] lg:mt-[-10px]">Select Product to Download your Discount Coupon:</label>
                            <div className="mb-10 sm:mb-4 lg:mb-0">
                            <CustomDropdown
                                options={[selectedProduct?.name]}
                                selected={selectedProduct?.name}
                                onSelect={(value) => setSelectedProduct((prev) => ({ ...prev, name: value }))}
                                className="bg-white w-full sm:w-3/4 md:w-1/2 lg:w-3/4 mx-auto lg:mx-0 sm:mt-[-20px] sm:mb-6 lg:mb-0 !mt-[-30px] sm:mb-4 lg:mt-[-10px]"
                            />
                        </div>
                    </div>

                        {/* Right Side Coupon */}
                        <div
                            className="mx-auto animate-celebrate mt-6 lg:mt-0 sm:mt-10 sm:mx-4 md:mx-auto w-full md:w-1/3 lg:w-1/4"
                            style={{
                                backgroundColor: '#FFFFE0',
                                borderRadius: '16px',
                                padding: '25px',
                                //marginLeft: '0',
                                //marginRight: '0',
                                marginTop: '-50px',
                                maxWidth: '400px',
                            }}
                        >
                            <div className="shadow-md rounded-lg p-4 text-center">
                                <div className="flex flex-wrap items-center justify-between mb-4">
                                    <img src={logo} alt="SmartPills Logo" className="w-24" />
                                    <p className="text-gray-600 text-sm font-semibold ">Coupon Date: {formattedDate}</p>
                                </div>

                                <h2 className="text-xl font-bold text-black-500 mb-2"> COUPON
                                    </h2>
                                    <h3 className="text-xl font-bold text-orange-500 mb-2">{selectedProduct?.name}</h3>
                                <div className="flex justify-center items-center mb-2">
                                    <p className="text-sm text-gray-600 mr-2">
                                        Quantity: {selectedProduct?.quantity || 'N/A'} | Vendor ID: {selectedProduct?.vendor_id || 'N/A'}
                                    </p>
                                    <img src={pharmacyLogo} alt="Pharmacy Logo" className="w-8" />
                                </div>

                                <p className="text-2xl font-bold text-green-500 mb-2">INR {selectedProduct?.discount || 'N/A'}</p>
                                <p className="line-through text-md text-red-500 mb-2">INR {selectedProduct?.mrp || 'N/A'}</p>
                                <p className="text-md text-green-500 font-semibold">Save {selectedProduct?.discounted_price || '0'}%</p>

                                <div className="bg-yellow-100 p-2 rounded-lg my-2 text-left text-sm">
                                    <p><strong>Product ID:</strong> {selectedProduct?.product_id || 'N/A'}</p>
                                    <p><strong>Packaging:</strong> {selectedProduct?.packaging || 'Standard Packaging'}</p>
                                    <p><strong>Available Quantity:</strong> {selectedProduct?.availableQuantity || 'N/A'}</p>
                                    <p><strong>Description:</strong> {selectedProduct?.description || 'No description available.'}</p>
                                    <p><strong>Contact:</strong> Call your pharmacy at {selectedProduct?.contact_number || 'N/A'} for more details.</p>
                                    <p><strong>Instructions:</strong> {selectedProduct?.instructions || 'Follow the instructions provided by your pharmacist.'}</p>
                                </div>

                                <p className="text-red-500 font-semibold text-sm">This coupon is valid for 48 hours only.</p>
                                <div className="flex flex-col items-center mt-6">
                                    <div className="flex justify-around w-full">
                                        <button onClick={handleText} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faCommentDots} />
                                        </button>
                                        <button onClick={handleEmail} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </button>
                                        <button onClick={handleDownload} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faDownload} />
                                        </button>
                                        <button onClick={handlePrint} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faPrint} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-red-500 text-center">Please login to see your coupons.</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default GetCoupons;
