import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import signup from "../assets/login.png";
import { IoCallOutline } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from "../components/AxiosClient";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IoCheckmarkCircle } from 'react-icons/io5'; // Import green checkmark icon

const SignIn = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('in'); // Default country code (India)
    const [isPhoneValid, setIsPhoneValid] = useState(false); // State for phone number validation
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // State for loading
    const navigate = useNavigate();

    // Handle phone number change and validation
    const handlePhoneChange = (value, data) => {
        setPhoneNumber(value.replace(data.dialCode, '').trim()); // Extract phone number without dial code
        setCountryCode(data.dialCode); // Set the selected country code

        // Basic validation: Check if phone number length is valid
        const phoneNumberRegex = /^[0-9]{10}$/; // You can modify the regex as per the validation rules
        setIsPhoneValid(phoneNumberRegex.test(value.replace(data.dialCode, '').trim()));
    };

    const handleSendOTP = async () => {
        try {
            // Check if phone number is provided
            if (!phoneNumber) {
                toast.error("Please enter your mobile number.");
                return;
            }

            setLoading(true); // Start loading
            const formData = new FormData();
            formData.append('phone_number', phoneNumber);
            formData.append('role', 'user');
            const response = await axiosClient.post('/auth/send_otp', formData);

            if (response.status === 200) {
                // OTP sent successfully
                toast.success("OTP sent successfully.");
                // Redirect to OTP page
                navigate(`/loginotp?phoneNumber=${phoneNumber}`);
            } else {
                // Failed to send OTP
                toast.error("Failed to send OTP.");
            }
        } catch (error) {
            // Error occurred
            console.error('Error sending OTP:', error);
            toast.error('User not found, Please try again later');
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <>
            <div className="lg:flex">
                <div className="lg:w-1/2 w-full">
                    <Link to="/"><img src={logo} className="sm:w-5/12 w-9/12 mx-auto mt-20 pt-2 mb-20" alt="Logo"></img></Link>
                    <div className="flex border-b-2 sm:w-5/12 w-9/12 mx-auto mb-5 border-gray-500 pb-3 relative">
                        {/* Phone Input with Country Code */}
                        <PhoneInput
                            country={countryCode} // Set default country code
                            value={`${countryCode || ''}${phoneNumber}`} // Concatenate country code and mobile number
                            onChange={handlePhoneChange} // Function to handle change
                            inputProps={{
                                name: 'mobile',
                                required: true,
                                placeholder: 'Mobile No.',
                                className: 'w-full focus:outline-none pl-12 py-2', // Added more left padding for country code button
                            }}
                            containerClass="w-full relative"  // Ensure the container width is full and position relative for the green tick
                            buttonClass="w-10 ml-2" // Adjust width of country code button to decrease length
                            searchClass="hidden" // Optional: hides search option if unnecessary
                        />
                        {/* Green Checkmark (visible when phone number is valid) */}
                        {isPhoneValid && (
                            <IoCheckmarkCircle
                                color="green"
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                                size={24}
                            />
                        )}
                    </div>
                    <div className="flex justify-center">
                        <button 
                            className='bg-gradient-to-r from-orange-400 to-yellow-400 px-9 py-3 rounded-lg mt-5 mb-9'
                            onClick={handleSendOTP}
                            disabled={loading || !isPhoneValid} // Disable button if phone number is invalid
                        >
                            {loading ? 'SENDING OTP...' : 'SEND OTP'} 
                        </button>
                    </div>
                    <ToastContainer />
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="flex justify-center">
                        <h2 className="font-semibold">Not Signed Up? Sign Up <Link to="/signup" className=" text-blue-700 ">here</Link></h2>
                    </div>
                </div>
                <img src={signup} className="hidden lg:block w-1/2 object-cover border border-l-4 border-orange-400" style={{ borderTopLeftRadius: '100px', height: '100vh' }} alt="Signup"></img>
            </div>
        </>
    );
};

export default SignIn;
