import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../components/AxiosClient";
import noteContext from "../context/notes/noteContext";
import help from "../assets/help.png";

const Helplogin = () => {
  const user = localStorage.getItem("user");
  const mail= localStorage.getItem("email")
  const userObj = JSON.parse(user);
  const { checkTokenExpiry } = useContext(noteContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState(mail);
  const [query, setQuery] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);

  const handleQueryChange = (e) => {
    const input = e.target.value;
    if (input.length <= 300) {
      setQuery(input);
      setCharCount(input.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in localStorage.");
        return;
      }

      checkTokenExpiry();
      const formData = new FormData();
      formData.append("email", email);
      formData.append("query_", query);

      const response = await axiosClient.post("/help/create_request", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(response.data.message, { autoClose: 3000 }); // Toast will vanish after 3 seconds

      // Show Thank You Page immediately after the form submission
      setShowThankYou(true);

      // Reset form state
      setEmail(mail);
      setQuery("");
      setCharCount(0);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleHomeRedirect = () => {
    navigate("/"); // Redirect to the home page
  };

  const handleCloseModal = () => {
    setShowThankYou(false); // Close the modal
  };

  return (
    <div className="relative">
      <Navbar />
      <div className="flex flex-col md:flex-row items-center justify-center my-10 md:mx-20">
        {/* Left Side: Image */}
        <div className="md:w-1/2 w-full mb-5 md:mb-0 flex justify-center md:justify-start relative">
          <img
            src={help}
            className="w-full h-full object-cover md:w-4/5 md:max-w-xl md:h-[90%] md:-ml-20 -mt-10 md:-mt-18"
          />
        </div>

        {/* Right Side: Form */}
        <div className="md:w-1/2 w-11/12 mx-auto">
          <div className="font-bold text-2xl">Need Help?</div>
          <div className="text-sm my-5">
            Please let us know if you are unable to reset password, create a new
            account or not getting the OTP.
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">Enter Email ID :</div>
            <input
              className="w-full border-b-2 border-gray-300 px-2"
              placeholder="Enter your email here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <textarea
              placeholder="Write your query here in maximum 300 words"
              className="w-full px-2 rounded-md border-2 border-gray-300 my-5"
              style={{ height: "100px" }}
              value={query}
              onChange={handleQueryChange}
            />
            <div className="text-right text-sm text-gray-500">
              {charCount}/300 characters
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-orange-400 to-yellow-400 rounded-md py-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer />

      {/* Thank You Modal */}
      {showThankYou && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-r from-orange-400 to-yellow-400 p-6 md:p-8 rounded-lg shadow-lg max-w-md w-full relative">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-black text-2xl font-bold cursor-pointer hover:text-white hover:scale-110 transition-transform duration-200"
            >
              ×
            </button>
            <h1 className="text-2xl md:text-3xl font-bold  mb-4 text-center">
              Thank You for Being Awesome Today!
            </h1>
            <p className="text-sm md:text-base text-gray-700 text-center mb-6">
              We have received your message and would like to thank you for
              writing to us.
              <br />
              We truly value your time and will address your concern to the best
              of our ability.
              <br />
              <br />
              Be in touch!
            </p>
            <button
              onClick={handleHomeRedirect}
              className="bg-orange-500 text-white px-4 py-2 text-sm font-medium rounded-md shadow-md hover:bg-orange-600 transition-all w-full"
            >
              HOME
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Helplogin;
