import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5"; // Import the close icon
import presImage from "../assets/pres.jpeg"; // Assuming this is the path for the image

const PrescriptionUpload = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Effect to disable scrolling
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (!file) {
            toast.error("Please upload a prescription file.");
            return;
        }

        // Simulate file upload success
        toast.success("Prescription uploaded successfully!");

        // Navigate to getCoupons with the relevant data
        navigate("/getCoupons", {
            state: {
                prescription_file: file,
                ...location.state // Pass the previous state data
            }
        });
    };

    const handleCancel = () => {
        navigate(-1); // Go back to the previous page
    };

    const handleSkipUpload = () => {
        navigate("/getCoupons", {
            state: {
                ...location.state // Pass previous state data
            }
        });
    };

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative inner-container bg-white shadow-lg rounded-lg p-8">
                {/* Close Icon in Top-Right */}
                <button
                    onClick={handleSkipUpload}
                    className="absolute top-2 right-2 text-black hover:text-gray-700"
                    aria-label="Close"
                >
                    <IoClose size={24} />
                </button>

                <img src={presImage} alt="Prescription" className="mb-4 w-1/3" />
                <h2 className="text-2xl mb-4">Upload Prescription</h2>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="mb-4"
                />
                <div className="flex space-x-4">
                    <button
                        onClick={handleUpload}
                        className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md"
                    >
                        Get Coupon
                    </button>
                    <button
                        onClick={handleCancel}
                        className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md"
                    >
                        Cancel
                    </button>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default PrescriptionUpload;
