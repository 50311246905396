import React, { useState, useEffect, useContext } from "react";
import { IoAdd, IoRemove, IoClose, IoPaperPlane } from "react-icons/io5";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import cartImage from "../assets/cart.jpeg";
import axiosClient from "../components/AxiosClient";
import noteContext from "../context/notes/noteContext";
import "../index.css";
import GetCoupons from "../pages/getCoupons";
import { FaEye } from "react-icons/fa"; // Import Eye icon for viewing the file
import { FaTimes } from 'react-icons/fa'; // Import the FaTimes (cross) icon for removing the file


const Cart = ({setcardfalse, setcartcount}) => {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);

  const handleRemoveFile = () => {
    setSelectedFileName(null);
    toast.info("File removed.");
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { checkTokenExpiry } = useContext(noteContext);

  const [cartItems, setCartItems] = useState(location.state?.cart || []);
  const addProductToCart = (product) => {
    setCartItems((prevItems) => [...prevItems, product]);
  };
  const [prescriptionFile, setPrescriptionFile] = useState(null);
  const [card, setcart] = useState(null);
  const [isPrescriptionSkipped, setIsPrescriptionSkipped] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [isCartVisible, setIsCartVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(!location.state?.cart);
  // const [couponid, setcouponid]=useState("")
  const uniqueKey = Date.now()

  const getAuthConfig = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    checkTokenExpiry();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  };

  useEffect(() => {

    if (!location.state?.cart) {
      const fetchCartItems = async () => {
        try {
          const config = getAuthConfig();
          const response = await axiosClient.post(
            "https://www.api.smartpills.in/cart/get_cart",
            null,
            config
          );

          console.log("API Response:hhhh", response.data);

          if (response.status === 200) {
            console.log("Cart Items:", response.data.cart);
            setCartItems(response.data.cart);
          } else {
            throw new Error("Failed to fetch cart items");
          }
        } catch (error) {
          console.error("Error fetching cart items:", error);
          //toast.error("Error loading cart items.");
        } finally {
          setIsLoading(false);
        }
      };
      fetchCartItems();
    } else {
      console.log("Initial Cart from Location State:", location.state.cart);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Calculate subtotal safely
    const total = cartItems.reduce(
      (sum, item) => sum + (item.mrp || 0) * (item.quantity || 0),
      0
    );
    console.log("Calculated Subtotal:", total);
    setSubtotal(total);
  }, [cartItems]);

  const handlePrescriptionChange = async (event, product_id, item) => {
    const file = event.target.files[0];


    // const mrp = (item.mrp || 0) * (item.quantity || 0)
    if (file) {
      setPrescriptionFile({ product_id, file });
      setSelectedFileName(file.name);
      setIsPrescriptionSkipped(false);
      toast.success("Prescription uploaded successfully!");
    }
  
    const discount = item?.smart_deal?.discount_percentage || 0;
    const mrp = (item.mrp || 0) * (item.quantity || 0);
    const discountPrice = mrp - mrp * (discount / 100);
  
    try {
      const couponid = await createcoupon(
        item.product_id,
        item.quantity,
        item.vendor_id,
        mrp,
        discount,
        discountPrice
      );
  
      setCouponDetails({
        prescription_file: file,
        product_id: item.product_id,
        vendor_id: item.vendor_id,
        quantity: item.quantity,
        discount: discountPrice,
        discounted_price: item.smart_deal?.discount_percentage,
        mrp: item.mrp,
        name: item.name,
        smart_deals: item.smart_deal,
        packaging: item.packaging,
        availableQuantity: item.available_quantity,
        couponid: couponid,
      });
  
      console.log("Coupon details stored. Ready for navigation.");
    } catch (error) {
      console.error("Error during prescription upload process:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  
  
  

  const handleRemovePrescription = () => {
    setPrescriptionFile(null);
    setSelectedFileName(null);
    setIsPrescriptionSkipped(false);
    toast.success("Prescription removed successfully!");
    console.log("Prescription removed");
  };

  const handleViewFile = () => {
    if (prescriptionFile) {
      const fileURL = URL.createObjectURL(prescriptionFile.file);
      window.open(fileURL, "_blank"); // Opens the file in a new tab
    }
  };

  const handleSkipPrescription = async (item) => {
    setcardfalse()
    // setcardtrue(true)
    setcart(true)
    setIsPrescriptionSkipped(true);
    setPrescriptionFile(null);
    setIsCartVisible(false)
    //toast.info("This medicine requires a prescription. Please carry one along to the chemist along with the coupon.");

    

    const discount = item?.smart_deal?.discount_percentage | 0;
    const mrp = (item.mrp || 0) * (item.quantity || 0)

    const discountPrice = mrp - mrp * (discount / 100);
    const couponid=await createcoupon(item.product_id,item.quantity,item.vendor_id,mrp,discount,discountPrice )
    console.log("Prescription skipped",couponid);

    navigate("/getCoupons", {
      state: {
        product_id: item.product_id,
        vendor_id: item.vendor_id,
        quantity: item.quantity,
        discount: discountPrice,
        discounted_price: item.smart_deal?.discount_percentage,
        mrp: item.mrp,
        name: item.name,
        smart_delas: item.smart_deal,
        packaging: item.packaging,
        availableQuantity: item.available_quantity,
        couponid:couponid
      },
    });

    // toast.info("Prescription skipped!");
    //console.log("Prescription skipped");
  };

  const handleGetCoupon = async (item) => {
    setcardfalse()
    // setcardtrue(true)
    setcart(true)
    console.log("GET COUPON button pressed"); // Log when the button is pressed
    console.log("Attempting to get coupon with the following data:");
    console.log("Cart Items:", item);
    console.log("Prescription File:", prescriptionFile);
    setIsCartVisible(false)
    console.log("Is Prescription Skipped:", isPrescriptionSkipped);
    const discount = item?.smart_deal?.discount_percentage | 0;
    const mrp = (item.mrp || 0) * (item.quantity || 0)

    const discountPrice = mrp - mrp * (discount / 100);
    console.log(discountPrice)
    const couponid =await createcoupon(item.product_id,item.quantity,item.vendor_id,mrp,discount,discountPrice )
    // if (!prescriptionFile && !isPrescriptionSkipped) {
    //   toast.error("Please upload a prescription or skip it before getting a coupon.");
    //   return;
    // }
    
    navigate("/getCoupons", {
      state: {
        product_id: item.product_id,
        vendor_id: item.vendor_id,
        quantity: item.quantity,
        discount: discountPrice,
        discounted_price: item.smart_deal?.discount_percentage,
        mrp: item.mrp,
        name: item.name,
        smart_delas: item.smart_deal,
        packaging: item.packaging,
        availableQuantity: item.available_quantity,
        couponid:couponid
      },
    });
  };

  const decrementcarditeam = async (product_main_id) => {
    try {
      const config = getAuthConfig();
      const formData = new FormData();
      formData.append('product_main_id', product_main_id);
      const response = await axiosClient.post(
        "https://www.api.smartpills.in/cart/decrement_quantity",
        formData,
        config
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        console.log("Cart Items:", response.data.cart);
      
        // setCartItems(response.data.cart);
      } else {
        throw new Error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      toast.error("Error loading cart items.");
    } finally {
      setIsLoading(false);
    }
  };


  const createcoupon = async (product_id,quantity,vendor_id, mrp, discount, discounted_price) => {
    try {
      const config = getAuthConfig();
      const formData = new FormData();
      formData.append("product_id", product_id)
      formData.append("quantity", quantity)
      formData.append("vendor_id", vendor_id)
      formData.append("mrp", mrp)
      formData.append("discount", discount)
      formData.append("discounted_price", discounted_price)

      const response = await axiosClient.post(
        "https://www.api.smartpills.in/coupons/create_coupon",
        formData,
        config
      );


      // setcouponid(couponid)

      console.log("API Response:", response.data.data[0].id);

      if (response.status === 200) {
        console.log("Cart Items:", response.data.cart);
        return response.data.data[0].id

        // console.log(response.data.id)
      
        // setCartItems(response.data.cart);
      } else {
        throw new Error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      toast.error("Error loading cart items.");
    } finally {
      setIsLoading(false);
    }
  };


  const remvoecarditeam = async (product_main_id) => {
    try {
      const config = getAuthConfig();
      const formData = new FormData();
      formData.append('product_main_id', product_main_id);
      const response = await axiosClient.post(
        "https://www.api.smartpills.in/cart/delete_cart",
        formData,
        config
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        console.log("Cart Items:", response.data.cart);
      
        // setCartItems(response.data.cart);
      } else {
        throw new Error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      toast.error("Error loading cart items.");
    } finally {
      setIsLoading(false);
    }
  };

  const incrementcartiteam = async (product_main_id) => {
    try {
      const config = getAuthConfig();
      const formData = new FormData();
      formData.append('product_main_id', product_main_id);
      const response = await axiosClient.post(
        "https://www.api.smartpills.in/cart/increment_quantity",
        formData,
        config
      );

      console.log("API Response:", response.data);

      if (response.status === 200) {
        console.log("Cart Items:", response.data.cart);
        // setCartItems(response.data.cart);
      } else {
        throw new Error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      toast.error("Error loading cart items.");
    } finally {
      setIsLoading(false);
    }
  };

  const incrementQuantity = (product_id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        // Call incrementcartiteam only when we find the correct item
        if (item.product_id === product_id) {
          incrementcartiteam(item.product_main_id);
          console.log(cartItems.reduce((sum, item1) => sum + (item1.quantity || 0), 0), "-==-=-")
          // setcartcount() // Call the function here
          return {
            ...item,
            quantity: item.quantity + 1 // Update quantity for the matched item
          };

        }
        // setcartcount()
        return item; // Return the item unchanged if no match
      })
    );

    console.log(`Increased quantity for ${product_id}`);
  };




  const decrementQuantity = (product_id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (item.product_id === product_id && item.quantity > 1) {
          decrementcarditeam(item.product_main_id)
          console.log(cartItems.reduce((sum, item1) => sum +(item1.quantity || 0), 0), "=-=-=-=-")
          
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
        // setcartcount()
        return item
      }

      )
    );
    console.log(`Decreased quantity for ${product_id}`);
  };


  const checkouthandle=async()=>{
    if (cartItems.length === 0) {
      alert("Your cart is empty. Please add some products before proceeding.");
      return; // Exit the function if the cart is empty
  }
    setIsCartVisible(false)
    alert(
      "Some medicines in the cart require a prescription. Please carry one along to the chemist along with the coupon."
    );
    console.log("lkklkl")
    console.log(cartItems, "=--==--")
    navigate("/checkoutcoupon", {
      state: {
        cartItems:cartItems
        // product_id: item.product_id,
        // vendor_id: item.vendor_id,
        // quantity: item.quantity,
        // discount: discountPrice,
        // discounted_price: item.smart_deal?.discount_percentage,
        // mrp: item.mrp,
        // name: item.name,
        // smart_delas: item.smart_deal,
        // packaging: item.packaging,
        // availableQuantity: item.available_quantity,
        // couponid:couponid
      },
      
    }
    
    )

  }

  const removeItem = (product_id) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => {
        if (item.product_id === product_id) {
          remvoecarditeam(item.product_main_id)
        }
        return item.product_id !== product_id
      })
     
    );
   
    setcartcount(cartItems.reduce((sum, item1) => sum + (item1.quantity || 0), 0))
    // toast.success("Item removed from cart");
    console.log(`Removed item with ID: ${product_id}`);
  };

  const closeCart = () => {
    setIsCartVisible(false);
    setcardfalse()
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="relative">
      {isCartVisible && (
        <div className="absolute top-1 right-5 bg-cream-200 shadow-lg rounded-md p-5 w-90 z-100">
          <img
            src={cartImage}
            alt="Cart"
            className="mb-2 w-24 h-24 rounded-md mx-auto"
          />
          <h2 className="text-lg font-bold mb-2 text-black text-center">
            Cart Items (
            {cartItems.reduce((sum, item) => sum + (item.quantity || 0), 0)}
            {setcartcount(cartItems.reduce((sum, item) => sum + (item.quantity || 0), 0))}
            )
          </h2>
          {isLoading ? (
            <div className="text-black text-center">Loading...</div>
          ) : cartItems.length === 0 ? (
            <div className="text-black text-center">Your cart is empty</div>
          ) : (
            <div className="overflow-auto max-h-60">
              {cartItems.map((item) => (
                <div
                  key={item.product_id}
                  className="bg-white p-4 mb-2 rounded shadow-sm border border-black"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-bold">{item.name || "Unknown Product"}</h3>
                      <p>Amount: ₹{(item.mrp || 0) * (item.quantity || 0)}</p>
                      <p>Quantity: {item.quantity}</p>
                    </div>
                    <div className="flex items-center space-x-1">
                      <button
                        className="bg-yellow-300 rounded p-2 transition duration-300 hover:bg-orange-400 flex items-center justify-center w-8 h-8"
                        onClick={() => incrementQuantity(item.product_id)}
                      >
                        <IoAdd />
                      </button>
                      <button
                        className="bg-yellow-300 rounded p-2 transition duration-300 hover:bg-orange-400 flex items-center justify-center w-8 h-8"
                        onClick={() => decrementQuantity(item.product_id)}
                      >
                        <IoRemove />
                      </button>
                      <button
                        className="bg-red-200 text-red-500 transition duration-300 hover:bg-orange-400 flex items-center justify-center w-8 h-8"
                        onClick={() => removeItem(item.product_id)}
                      >
                        <IoClose />
                      </button>
                    </div>
                  </div>
  
                  <div className="mt-4 w-full">
                    {/* Conditional Rendering for Upload/Remove File */}
                    {!prescriptionFile || prescriptionFile.product_id !== item.product_id ? (
                      item.medicine_type === "Rx" ||
                      item.medicine_type === "Prescription" ||
                      item.medicine_type === "Drugs" ||
                      item.type === "Rx" ||
                      item.type === "Prescription" ||
                      item.type === "Drugs" ? (
                        <label
                          htmlFor={`file-upload-${item.product_id}`}
                          className="flex items-center justify-center cursor-pointer bg-white border border-gray-300 rounded-md px-4 py-2 w-full"
                        >
                          <IoPaperPlane className="mr-2 text-gray-500" />
                          <span className="text-gray-500">Upload File</span>
                          <input
                            type="file"
                            id={`file-upload-${item.product_id}`}
                            onChange={(e) => handlePrescriptionChange(e, item.product_id, item)}
                            accept="image/*,.pdf"
                            className="hidden"
                          />
                        </label>
                      ) : null
                    ) : (
                      <div className="flex items-center justify-between mt-2">

                        <div className="border border-black px-4 py-2 rounded-md w-full flex items-center justify-between">

                        <span className="text-black">{prescriptionFile.file.name}</span>
                        
                        <div className="flex items-center space-x-2">
              {/* Eye icon to view the file */}
              <button onClick={handleViewFile} className="text-blue-500">
                <FaEye />
              </button>
              {/* Remove button */}
              <button onClick={handleRemovePrescription} className="text-red-500">
                <FaTimes />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  
                  {/* Skip Prescription Button */}
                  {item.medicine_type === "Rx" ||
                  item.medicine_type === "Prescription" ||
                  item.medicine_type === "Drugs" ||
                  item.type === "Rx" ||
                  item.type === "Prescription" ||
                  item.type === "Drugs" ? (
                    <div className="mt-2 flex justify-center">
                      <button
                        onClick={() => {
                          handleSkipPrescription(item);
                          alert(
                            "This medicine requires a prescription. Please carry one along to the chemist along with the coupon."
                          );
                        }}
                        className="bg-yellow-300 text-black px-2 py-1 rounded-md transition duration-300 hover:bg-orange-400"
                      >
                        Skip Prescription
                      </button>
                    </div>
                  ) : null}
  
                  {/* Get Coupon Button */}
                  {prescriptionFile && prescriptionFile.product_id === item.product_id && (
                    <div className="mt-2 flex justify-center">
                      <button
                        onClick={() => handleGetCoupon(item)}
                        className="bg-yellow-300 text-black px-2 py-1 rounded-md transition duration-300 hover:bg-orange-400"
                      >
                        GET COUPON
                      </button>
                    </div>
                  )}
  
                  {/* OTC Get Coupon Button */}
                  {item.medicine_type === "Non Rx" ||
                  item.medicine_type === "OTC" ||
                  item.type === "OTC" ||
                  item.type === "Non Rx" ? (
                    <div className="mt-2 flex justify-center">
                      <button
                        onClick={() => handleGetCoupon(item)}
                        className="bg-yellow-300 text-black px-2 py-1 rounded-md transition duration-300 hover:bg-orange-400"
                      >
                        GET COUPON
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-between mt-4 p-2 bg-white border border-gray-300 rounded-md shadow-sm">
            <h3 className="font-bold text-black">Total:</h3>
            <h3 className="font-bold text-orange-500 text-xl">₹{subtotal}</h3>
          </div>
  
          <div className="flex justify-between mt-4">
            <button
              onClick={() => closeCart()}
              className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-2 py-1 mr-4 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
            >
              CLOSE
            </button>
            <button
              onClick={
                checkouthandle
                // Ensure you have the navigate function set up
              }
              className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-2 py-1 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
            >
              CHECKOUT
            </button>

            {/* <button
              onClick={handleGetCoupon}
              className={`mt-2 w-32 px-2 py-1 rounded-md transition duration-300 ease-in-out transform ${
                prescriptionFile || isPrescriptionSkipped
                  ? "bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-2 py-1 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                  : "bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-2 py-1 cursor-not-allowed"
              }`}
              disabled={!prescriptionFile && !isPrescriptionSkipped}
            >
              GET COUPON
            </button> */}
          </div>
        </div>
      )}
    </div>

  );
};

// const GetCoupons = () => {
//   const location = useLocation(); // Get the current location from React Router
//   const { checkTokenExpiry } = useContext(noteContext); // Access context for token expiry check

//   const [selectedProduct, setSelectedProduct] = useState(null); // State to store the selected product
//   const [isLoggedIn, setIsLoggedIn] = useState(true); // State for login status
//   const productDetails = location.state || {}; // Get product details from location state or default to an empty object

//   useEffect(() => {
//     // Setting product from location.state when component mounts
//     if (productDetails.name) {
//       setSelectedProduct({
//         product_id: productDetails.product_id,
//         vendor_id: productDetails.vendor_id,
//         name: productDetails.name,
//         mrp: productDetails.mrp,
//         quantity: productDetails.requiredQuantity,
//         discount: productDetails.discount,
//         discounted_price: productDetails.discountPrice,
//         packaging: productDetails.packaging,
//         availableQuantity: productDetails.available_quantity,
//         description: productDetails.description,
//         contact_number: productDetails.contact_number,
//         instructions: productDetails.instructions,
//       });
//     }
//   }, [productDetails]);

//   return (
//     <div>
//       {/* Your UI for GetCoupons goes here */}
//       {selectedProduct && <div>Selected Product: {selectedProduct.name}</div>}
//     </div>
//   );
// };

export default Cart;