import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axiosClient from '../components/AxiosClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Alliance = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    company_name: '',
    buisness_address: '',
    how_did_you_hear_about_us: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const navigate = useNavigate();
  const [showThankYou, setShowThankYou] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach(key => {
      data.append(key, formData[key]);
    });
  
    try {
      const response = await axiosClient.post('/alliances/create_alliance', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Response:', response.data);
      toast.success('Form submitted successfully!');
      setShowThankYou(true);
      setFormData({
        name: '',
        email: '',
        mobile: '',
        company_name: '',
        buisness_address: '',
        how_did_you_hear_about_us: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit the form.');
    }
  };

  const handleCloseModal = ()=> {
    setShowThankYou(false);
  };

  const handleHomeRedirect = () => {
    handleCloseModal();
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow flex flex-col items-center justify-center bg-gray-100 py-8">
        <h2 className="text-3xl font-bold mb-6">Alliances</h2>
        <form className="w-full max-w-lg bg-white p-8 shadow-md rounded" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Mobile:</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company Name:</label>
            <input
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Business Address:</label>
            <input
              type="text"
              name="buisness_address"
              value={formData.buisness_address}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">How did you hear about us:</label>
            <input
              type="text"
              name="how_did_you_hear_about_us"
              value={formData.how_did_you_hear_about_us}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Message:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </form>
      </div>
            {/* Thank You Modal */}
            {showThankYou && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-r from-orange-400 to-yellow-400 p-6 md:p-8 rounded-lg shadow-lg max-w-md w-full relative">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-black text-2xl font-bold cursor-pointer hover:text-white hover:scale-110 transition-transform duration-200"
            >
              ×
            </button>
            <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center">
              Thank You for Being Awesome Today!
            </h1>
            <p className="text-sm md:text-base text-gray-700 text-center mb-6">
              We have received your message and would like to thank you for
              writing to us.
              <br />
              We truly value your time and will address your concern to the best
              of our ability.
              <br />
              <br />
              Be in touch!
            </p>
            <button
              onClick={handleHomeRedirect}
              className="bg-orange-500 text-white px-4 py-2 text-sm font-medium rounded-md shadow-md hover:bg-orange-600 transition-all w-full"
            >
              HOME
            </button>
          </div>
        </div>
      )}
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Alliance;
