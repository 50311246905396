import React, { useState, useCallback, useContext } from 'react';
import { IoSearch } from "react-icons/io5";
import axiosClient from './AxiosClient';
import axios from 'axios';

import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';


const SearchNavbar = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);
    const [show, setShow] = useState(true);
    const [pin, setpin]=useState('')
    // const pin = localStorage.getItem('PIN');
    const navigate = useNavigate();
    const { checkTokenExpiry } = useContext(noteContext);


    const handle_pincode = async()=>{
        navigator.geolocation.getCurrentPosition(
            async(position) => {
                const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;  // Replace with your API key
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;
                const response =  await axios.get(url);
                const results = response.data.results;
                if (results.length > 0) {
                    const addressComponents = results[0].address_components;
                    const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
                    if (postalCodeComponent) {
                        setpin(postalCodeComponent.long_name)
                    }
                    else{
                        setpin(localStorage.getItem("PIN"))

                    }
                }
            })}

    const fetchSearchResults = useCallback(
        debounce(async (query) => {
            if (!query) {
                setSearchResults([]);
                setShow(false);  // Hide the dropdown when the query is empty
                return;
            }
            const token = localStorage.getItem('token');
            if (!token) {
                setError('You are not logged in.');
                setSearchResults([]);
                return;
            }
            try {
                setLoading(true); // Start loader
                setError(null);
                checkTokenExpiry();
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                };

                const response = await axiosClient.post(
                    `/products/search_products/${query}`,
                    {},
                    config
                );

                console.log('Search response:', response.data);
                const names = response.data?.data?.flat()?.map(item => ({ name: item.name, id: item._id })) || [];
                setSearchResults(names);
                setLoading(false); // Stop loader after search completes
            } catch (error) {
                console.error('Error searching products:', error);
                setLoading(false); // Stop loader on error
                setError('Error fetching search results');
            }
        }, 300),
        []
    );

    const handleInputChange = (e) => {
        setShow(true);
        handle_pincode()
        const query = e.target.value;
        setSearchQuery(query);
        fetchSearchResults(query);
    };

    const handleSearchItemClick = (id, name) => {
        setLoading(true); // Start the loader when an item is selected
        const encodedName = encodeURIComponent(name);
        console.log(encodedName, id,"-==-=-=-=-")
        setShow(false);
        // Delay navigation slightly to ensure loader is visible
        
        setTimeout(() => {
           
            setLoading(false)
           
            navigate(`/tablet-info?id=${id}&name=${encodedName}&pincode=${pin}`)
        }, 500); // Adjust delay if needed
        const handleCloseDropdown = () => {
            setShow(false); // Hide the dropdown when cross is clicked
          };
    };

    return (
        <>
            <div className="relative lg:flex bg-white w-6/12 my-2 hidden" style={{ marginLeft: "-50px" }}>
                <IoSearch fontSize="1.4rem" className="my-auto ml-5 absolute top-1/2 transform -translate-y-1/2" />
                <input 
                    placeholder="Type your medicine name" 
                    className="w-full ml-14 pl-6 pr-3 py-2 focus:outline-none rounded-lg" 
                    onChange={handleInputChange} 
                    value={searchQuery} 
                />
                <div className="absolute top-full mt-1">
                    {/*loading && <p className="text-gray-700 py-1 px-2 border-orange-400 rounded-md border-2 bg-white">Fetching results...</p>*/}
                    {error && <p className="text-red-500 bg-white py-1 px-4 border-orange-400 rounded-md border-2">{error}</p>}
                </div>
            </div>

            {loading && (
  <div className="absolute inset-0 z-30 flex flex-col items-center justify-center bg-black bg-opacity-50">
    <div className="relative flex items-center justify-center">
      <div
        className="loader"
        style={{
          width: '80px',
          height: '80px',
          border: '8px solid rgba(255, 255, 255, 0.8)', // Mostly white with transparency
          borderTop: '8px solid orange', // Orange top for spinning effect
          borderRadius: '50%',
          animation: 'spin 2s linear infinite', // Slower spin
        }}
      />
      <img
        src="/loader.png" // Path to your static loader image
        alt="Loading..."
        style={{
          width: '60px',
          height: '60px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Centers the image inside the loader
        }}
      />
    </div>
  </div>
)}


            {searchResults.length > 0 && show && (
                <div className="absolute z-10 bg-white p-4 mt-14 ml-20 rounded-lg shadow-lg" style={{ maxHeight: "200px", overflowY: "auto" }}>
                    <ul>
                        {searchResults.map((item, index) => (
                            <li 
                                key={index} 
                                className="text-gray-700 cursor-pointer hover:bg-gray-200 p-2" 
                                onClick={() => handleSearchItemClick(item.id, item.name)} // Trigger the loader on click
                            >
                                {item.name}
                            </li>
))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default SearchNavbar;
