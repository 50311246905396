import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Tablet from "./Tablet";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from "./AxiosClient";
import noteContext from "../context/notes/noteContext";
import { IoLocation, IoCheckmarkCircle } from "react-icons/io5";

const Pricing = ({ productId, product, pincode, token }) => {
    const { pin, setPin } = useContext(noteContext); // Use context for pin
    const [tempPin, setTempPin] = useState(''); // State for temporary pincode input
    const [errorMessage, setErrorMessage] = useState(""); // Error message state
    const [vendors, setVendors] = useState([]);
    const [sortOption, setSortOption] = useState('distance');
    const [modalOpen, setModalOpen] = useState(false);
    const [newPincode, setNewPincode] = useState(pincode); // State to hold new pincode
    const [showToast, setShowToast] = useState(false); // State to control toast visibility
    const { checkTokenExpiry } = useContext(noteContext);

    useEffect(() => {
        if (newPincode) {
            fetchVendorData(product.name);
        }
    }, [newPincode, product.name, sortOption]);

    const fetchVendorData = async (name) => {
        try {
            const formData = new FormData();
            formData.append("pincode", newPincode);

            if (sortOption === 'price_to_high_to_low' || sortOption === 'price_to_low_to_high') {
                formData.append("search", sortOption);
            } else {
                formData.append("search", ''); // For distance option, pass empty string
            }

            const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
            checkTokenExpiry();
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            };

            const response = await axiosClient.post(
                `/products/get_vendors/${name}`,
                formData,
                config
            );

            setVendors(response.data.data || []);
        } catch (error) {
            console.error("Error fetching vendors:", error);
            toast.error("An error occurred while fetching vendors.");
        }
    };

    const validateAndSetPincode = () => {
        // Clear any previous error message
        setErrorMessage("");

        if (tempPin.length === 6 && /^\d+$/.test(tempPin)) {
            setPin(tempPin);
            localStorage.setItem("PIN", tempPin);
            setNewPincode(tempPin);
            setModalOpen(false); // Close modal after setting pincode
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
        } else {
            setErrorMessage("Invalid Pincode");
        }
    };

    const handleInputChange = (value) => {
        // Allow input only if length is <= 6
        if (value.length <= 6) {
            setTempPin(value);

            // Clear the error message if pincode length is valid or being edited
            if (value.length !== 6 || !/^\d+$/.test(value)) {
                setErrorMessage(""); // Clear the error message while typing
            }
        }
    };

    const handleLocationPincode = async () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;
                const response = await axios.get(url); // Use axios here for geolocation API
                const results = response.data.results;
                if (results.length > 0) {
                    const addressComponents = results[0].address_components;
                    const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
                    if (postalCodeComponent) {
                        setPin(postalCodeComponent.long_name);
                        localStorage.setItem("PIN", postalCodeComponent.long_name);
                        setNewPincode(postalCodeComponent.long_name);
                    }
                }
            }
        );
    };

    return (
        <div className="p-5">
            <h1 className="text-2xl font-bold mb-5">Available Vendors</h1>

            <div className="flex flex-col sm:flex-row justify-between px-5 py-5 rounded-lg border border-orange-500 bg-gray-100 mb-5">
                <div
                    className="cursor-pointer text-center sm:text-left"
                    onClick={() => setModalOpen(true)} // Open the modal on pincode click
                >
                    {newPincode}
                </div>
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="border border-gray-500 rounded-lg px-2 py-1 mt-2 sm:mt-0 sm:w-40"
                >
                    <option value="distance">Distance</option>
                    <option value="price_to_high_to_low">Price: High to Low</option>
                    <option value="price_to_low_to_high">Price: Low to High</option>
                </select>
            </div>

            <div className="mt-5 space-y-4">
                {vendors.length > 0 ? (
                    vendors.map((vendor) => (
                        <Tablet
                            key={vendor.vendor_id}
                            vendor={vendor}
                            productId={productId}
                            name={vendor.name}
                            packaging={vendor.packaging}
                            quantity={vendor.available_quantity}
                            currency={vendor.currency}
                            prev={vendor.prev_price}
                            mrp={vendor.Mrp}
                            save={vendor.discount_percentage} 
                        />
                    ))
                ) : (
                    <div>No vendors found</div>
                )}
            </div>

            {/* Modal for Pincode Input */}
            {modalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg w-11/12 sm:w-1/3 max-w-sm">
                        <h2 className="text-xl font-semibold mb-4 text-center">Set Your Location</h2>

                        {/* Current Location Button */}
                        <div className="flex justify-center bg-blue-500 text-white rounded-lg mx-auto py-2 mb-4">
                            <button onClick={handleLocationPincode} className="flex items-center space-x-2 px-3 py-1.5 w-auto text-sm">
                                <IoLocation className="mr-1" />
                                <span>Use my current location</span>
                            </button>
                        </div>

                        <p className="text-center mb-4 text-sm">Note: Your browser will ask for permission first</p>
                        <div className="mx-auto w-max mt-3 text-center">OR</div>

                        {/* Pincode Input */}
                        <div className="relative w-full mt-4">
                            <input
                                type="text"
                                placeholder="Enter PIN code"
                                className={`p-2 pl-3 pr-10 border rounded-lg w-full max-w-xs h-10 ${
                                    errorMessage ? "border-orange-500" : "border-gray-300"
                                }`}
                                value={tempPin}
                                onChange={(e) => handleInputChange(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        validateAndSetPincode();
                                    }
                                }}
                                maxLength="6"
                            />
                            {tempPin.length === 6 && (
                                <IoCheckmarkCircle
                                    color="green"
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
                                    size={24}
                                />
                            )}
                        </div>

                        <p className="text-sm text-center mt-2">*Enter a valid 6 digit pincode</p>
                        {errorMessage && (
                            <div className="text-orange-500 mt-2 text-center">
                                <strong>{errorMessage}</strong>
                            </div>
                        )}

                        {/* Set Location Button */}
                        <div className="flex justify-center mt-4">
                            <button
                                className="bg-gradient-to-r font-semibold text-md from-orange-400 to-yellow-400 text-white px-3 py-1.5 rounded-md hover:bg-blue-600 w-auto"
                                onClick={validateAndSetPincode}
                                disabled={tempPin.length !== 6}
                            >
                                Set Location
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Show Toast notification */}
            {showToast && <ToastContainer />}
        </div>
    );
};

export default Pricing;
