import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import Pricing from "../components/Pricing";
import Drug from "../components/Drug";
import Interaction from "../components/Interaction";
import Images from "../components/Images";
import Uses from "../components/Uses";
import Warnings from "../components/Warnings";
import SideEffects from "../components/Sideeffects";
import axiosClient from "../components/AxiosClient";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import noteContext from "../context/notes/noteContext";

const Tabletinfo = () => {
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState("");
  const [pincode, setPincode] = useState("");
  const [token, setToken] = useState("");
  const [prodname, setName] = useState("");
  const [activeSection, setActiveSection] = useState("pricing");
  const location = useLocation();
  const { checkTokenExpiry } = useContext(noteContext);
  
  // Reference for button container
  const buttonContainerRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    var pincodeParam = params.get("pincode");
    console.log(pincodeParam, "pincodeParam")
    if (pincodeParam===null){
      var pincodeParam =localStorage.getItem("PIN")

    }
    const name = decodeURIComponent(params.get("name"));
    const storedToken = localStorage.getItem("token");

    setProductId(id);
    setPincode(pincodeParam);
    setToken(storedToken);
    setName(name);

    if (id && storedToken) {
      getProductInfo(id, pincodeParam, storedToken, name);
    }
  }, [location.search]);

  const getProductInfo = async (productId, pincode, token, name) => {
    try {
      const formData = new FormData();
      formData.append("pincode", pincode);
      checkTokenExpiry();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosClient.post(
        `/products/get_product/${productId}?name=${name}`,
        formData,
        config
      );

      setProduct(response.data.data[0]);
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error("An error occurred while fetching product info.");
      }
    }
  };

  const handleAddToBookmark = async () => {
    try {
      checkTokenExpiry();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axiosClient.post(
        `/bookmarks/add_bookmark/${productId}`,
        null,
        config
      );

      toast.success(response.data.message);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      toast.error("Error adding bookmark");
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // Scroll left function
  const scrollLeft = () => {
    if (buttonContainerRef.current) {
      buttonContainerRef.current.scrollBy({
        left: -200, // Scroll by 200 pixels to the left
        behavior: 'smooth' // Smooth scroll animation
      });
    }
  };

  // Scroll right function
  const scrollRight = () => {
    if (buttonContainerRef.current) {
      buttonContainerRef.current.scrollBy({
        left: 200, // Scroll by 200 pixels to the right
        behavior: 'smooth' // Smooth scroll animation
      });
    }
  };

  if (!product) return null;

  // Check description
  const productDescription = product.description && product.description.trim() !== "" 
    ? product.description 
    : "The information requested by you is currently unavailable. We apologize for the same.";

  const isProductDataMissing = !product.medicine_type || !product.packaging || !product.salt_composition;

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow mx-5 pt-10">
        <div className="flex">
          <div className="bg-gray-200 overflow-hidden" style={{ width: "200px", height: "200px" }}>
            {product.image_url ? (
              <img
                src={product.image_url}
                alt={`${product.name} medicine`}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <img
                  src="/tri.jpg"
                  alt="Default Warning"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>
          <div className="ml-5">
            <div className="text-3xl font-semibold">{product.name}</div>
            <div>
              {product.medicine_type} • {product.packaging} • {product.salt_composition}
            </div>
            <button
              className="text-white rounded-md bg-gradient-to-r from-orange-400 to-yellow-400 px-2 py-2 my-2"
              onClick={handleAddToBookmark}
            >
              Add to Bookmark
            </button>

            <div className="text-sm">
              {productDescription}
            </div>
          </div>
        </div>

        {/* Options section */}
        <div className="bg-gradient-to-r from-orange-400 to-yellow-400 mt-5 py-3 mx-5 relative">
          {/* Button Container */}
          <div ref={buttonContainerRef} className="flex overflow-x-auto scrollbar-hide justify-start space-x-2">
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "pricing" ? "font-bold" : ""}`} onClick={() => handleSectionChange("pricing")}>
              Pricing
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "drug" ? "font-bold" : ""}`} onClick={() => handleSectionChange("drug")}>
              Drug Info
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "interaction" ? "font-bold" : ""}`} onClick={() => handleSectionChange("interaction")}>
              Interaction
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "images" ? "font-bold" : ""}`} onClick={() => handleSectionChange("images")}>
              Images
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "uses" ? "font-bold" : ""}`} onClick={() => handleSectionChange("uses")}>
              Uses
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "warnings" ? "font-bold" : ""}`} onClick={() => handleSectionChange("warnings")}>
              Warnings
            </button>
            <button className={`mx-2 my-1 px-4 py-2 rounded ${activeSection === "sideEffects" ? "font-bold" : ""}`} onClick={() => handleSectionChange("sideEffects")}>
              Side Effects
            </button>
          </div>

          {/* Scroll Buttons - Positioned outside the button container but vertically centered */}
          <div className="absolute inset-y-0 left-[-30px] flex items-center">
            <button className="bg-white rounded-full p-2 shadow" onClick={scrollLeft}>
              &lt;
            </button>
          </div>
          <div className="absolute inset-y-0 right-[-30px] flex items-center">
            <button className="bg-white rounded-full p-2 shadow" onClick={scrollRight}>
              &gt;
            </button>
          </div>
        </div>

        {/* Render content based on active section */}
        {activeSection === "pricing" && <Pricing product={product} productId={productId} pincode={pincode} token={token} />}
        {activeSection === "drug" && <Drug product={product} />}
        {activeSection === "interaction" && <Interaction product={product} />}
        {activeSection === "images" && <Images product={product} />}
        {activeSection === "uses" && <Uses product={product} />}
        {activeSection === "warnings" && <Warnings product={product} />}
        {activeSection === "sideEffects" && <SideEffects product={product} />}
      </main>
      <ToastContainer />
    </div>
  );
};

export default Tabletinfo;