import React, { useState, useEffect } from 'react';
import noteContext from './noteContext';
import axiosClient from '../../components/AxiosClient';
import { useNavigate, useLocation } from 'react-router-dom';
const NoteState = (props) => {
    const location = useLocation()
    const [pin, setPin] = useState('');
    const [cartcount, setcartcount]=useState(0)
    const navigate = useNavigate();
    const checkTokenExpiry = async () => {
        if (location.pathname == '/') {
            return 
            // checkTokenExpiry();
        }

        if (location.pathname=="/login-help"){
            return 
        }

        if (location.pathname=="/query"){
            return 
        }

        if (location.pathname=="/onboarding"){
            return 
        }
        else{
            
            const adminToken = localStorage.getItem('token');
            if (!adminToken) {
                navigate("/signin")
            }
    
            try {
                const response = await axiosClient.post('/auth/token_expiry_check', null, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
    
                if (response.data.code !== 200) {
                    navigate("/signin")
                }
            } catch (error) {
                navigate("/signin")
            }

        }
       
    };


    // useEffect(() => {
    //     if (location.pathname == '/') {
    //         return 
    //         // checkTokenExpiry();
    //     }
    // }, [location.pathname]); 

    useEffect(() => {
        const handleUnload = () => {
            localStorage.removeItem('PIN');
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    return (
        <noteContext.Provider value={{ pin, setPin, checkTokenExpiry, cartcount, setcartcount }}>
            {props.children}
        </noteContext.Provider>
    );
};

export default NoteState;
