// src/components/CustomDropdown.jsx
import React, { useState } from 'react';

const CustomDropdown = ({ options, selected, onSelect }) => {

    console.log(options, "options=--=-==-=")
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                style={{
                    width: '100%',
                    padding: '0.5rem 1rem',
                    backgroundColor: 'white',
                    border: '1px solid #d1d5db',
                    borderRadius: '0.375rem',
                    textAlign: 'left',
                    position: 'relative',
                    outline: 'none',
                    transition: 'box-shadow 0.2s ease',
                }}
                className="focus:ring focus:ring-orange-500"
            >
                {selected}
                {/* Arrow indicator */}
                <span
                    style={{
                        position: 'absolute',
                        right: '1rem',
                        top: '50%',
                        transform: `translateY(-50%) ${isOpen ? 'rotate(180deg)' : ''}`,
                        transition: 'transform 0.2s ease',
                    }}
                >
                    ▼ {/* You can also use an icon here, e.g., FontAwesome */}
                </span>
            </button>
            {isOpen && (
                <ul className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg z-10">
                    {options.map((option) => (
                        <li
                            key={option.object_id}
                            onClick={() => handleOptionClick(option)}
                            style={{
                                padding: '0.5rem 1rem',
                                cursor: 'pointer',
                                transition: 'background-color 0.2s ease',
                            }}
                            className="hover:bg-yellow-100"
                        >
                            {console.log(option)}
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
