import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Navbar />
      <div className="mx-4 lg:mx-20 my-10">
        <div
          style={{ textAlign: "center" }}
          className="font-semibold text-2xl mb-5"
        >
          About SmartPills
        </div>
        <h1 className="mb-3 text-center">
          We believe everyone deserves affordable medicines and our mission is
          to build better ways for people to find the right medicine at the
          best price.
        </h1>
        <h1 className="mb-3 text-center">
          We provide our consumers with the knowledge, choice, and care they
          need to stay healthy, that’s why we started SmartPills to make
          medicine pricing transparent.
        </h1>
        <h1 className="mb-5 text-center">
          SmartPills helps people across the country by providing affordable
          and easier access to medicine, leading to better health.
        </h1>
        <div>
          <h1
            className="font-semibold text-center"
            style={{ fontSize: "1.1rem" }}
          >
            SmartPills Values, Mission & Vision
          </h1>
          <div className="lg:flex lg:mt-10">
            {/* Values Section */}
            <div className="border-2 border-orange-500 rounded-lg w-full lg:w-1/2 mb-6 lg:mb-0 px-4 py-4 lg:ml-10 lg:mr-5">
              <h1
                style={{ fontSize: "1.2rem" }}
                className="font-bold mb-2 text-center lg:text-left"
              >
                VALUES:
              </h1>
              <ol className="list-disc pl-5 mt-0 space-y-1">
                <li>We treat everyone honestly, and respectfully.</li>
                <li>
                  We know we can succeed in getting all our consumers access to
                  the medicines they need, and we will not stop until we do.
                </li>
                <li>
                  We believe everyone should have access to high-quality,
                  affordable medicines, no matter who they are or what their
                  circumstances.
                </li>
                <li>
                  Our success is defined by the help we provide to people in
                  need.
                </li>
                <li>
                  We present information that is objective and easy to
                  understand, so consumers can make the best decisions for
                  their needs.
                </li>
                <li>
                  We hold ourselves and our colleagues to the highest standards
                  of integrity, responsibility, and quality in all that we do.
                </li>
              </ol>
            </div>

            {/* Mission and Vision Section */}
            <div className="w-full lg:w-1/2 lg:ml-5 lg:mr-10 space-y-4">
              <div className="bg-gradient-to-r from-orange-400 to-yellow-400 rounded-lg text-white p-4">
                <h1
                  className="font-bold mb-3 text-center lg:text-left"
                  style={{ fontSize: "1.2rem" }}
                >
                  MISSION:
                </h1>
                <h1 className="px-4 py-5">
                  Help Indians to compare offline medicine prices and buy at
                  the best price within their reach.
                </h1>
              </div>
              <div className="bg-gradient-to-r from-orange-400 to-yellow-400 rounded-lg text-white p-4">
                <h1
                  className="font-bold mb-3 text-center lg:text-left"
                  style={{ fontSize: "1.2rem" }}
                >
                  VISION:
                </h1>
                <h1 className="px-4 py-4">
                  Help the Indian community with medicine prices that are
                  affordable, trusted, and within their reach.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
